// src/components/ChannelList.jsx
import React, { useState, useEffect } from "react";
import axios from "axios";
import './ChannelList.css';

const ChannelList = ({ onSelectChannel }) => {
  const [playlist, setPlaylist] = useState([]);
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    const loadDefaultPlaylist = async () => {
      try {
        const response = await axios.get("/free.m3u8");
        const parsedPlaylist = parseM3U(response.data);
        setPlaylist(parsedPlaylist);

        const uniqueGroups = Array.from(
          new Set(parsedPlaylist.map((channel) => channel.group))
        );
        setGroups(uniqueGroups);
      } catch (error) {
        console.error("Failed to load default playlist:", error);
      }
    };

    loadDefaultPlaylist();
  }, []);

  const parseM3U = (text) => {
    const lines = text.split("\n");
    const playlist = [];
    let currentItem = {};

    lines.forEach((line) => {
      if (line.startsWith("#EXTINF:")) {
        const attrs = line.split(",");
        currentItem.name = attrs[1]?.trim() || "Unknown Channel";
        const tvgAttrs = attrs[0].match(
          /tvg-id="([^"]*)".*tvg-name="([^"]*)".*tvg-logo="([^"]*)".*group-title="([^"]*)"/
        );
        if (tvgAttrs) {
          currentItem.group = tvgAttrs[4];
          currentItem.tvgLogo = tvgAttrs[3];
        } else {
          currentItem.group = "Unknown Group";
          currentItem.tvgLogo = "";
        }
      } else if (line && !line.startsWith("#")) {
        currentItem.url = line.trim();
        playlist.push(currentItem);
        currentItem = {};
      }
    });

    return playlist;
  };

  return (
    <div className="channel-list-container">
      <h2 className="header">Lista de Canais</h2>
      <div className="channel-list">
        {groups.map((group) => (
          <div key={group} className="group">
            <h3 className="group-header">{group}</h3>
            <ul className="channel-group">
              {playlist
                .filter((channel) => channel.group === group)
                .map((channel, index) => (
                  <li
                    key={`${channel.name}-${index}`}
                    onClick={() => {
                      onSelectChannel(channel.url);
                    }}
                    className="channel-item"
                  >
                    <img src={channel.tvgLogo} alt={channel.name} className="channel-logo" />
                    {channel.name}
                  </li>
                ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ChannelList;
