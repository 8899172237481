import React, { Component } from "react";
import axios from "axios";
import Download from "../Torrents/DownloadItem.jsx";
import { updateLink } from "../../Firebase/lists";
import './styles.css'; // Certifique-se de ajustar o caminho conforme necessário

class MovieServers extends Component {
  state = {
    selectedOption: "",
    openVideo: false,
    openNotif: false,
    iframeLoaded: false,
    loading: true,
    pop: [],
    display: true,
    links: false,
    movie_link: "",
    addedmsg: false,
    errormsg: false,
    populardownload: "",
    getData: "",
    server: localStorage.getItem("server_movie") || 1, // adiciona o estado do servidor
  };

  componentDidMount() {
    let pop_status = localStorage.getItem('server_movie');
    if (pop_status == 2) {
      axios.get(`https://yts.mx/api/v2/list_movies.json?query_term=${this.props.id}&with_images=true&with_cast=true`)
        .then((response) => {
          this.setState({
            populardownload: response.data,
          });
          const gg = this.state.populardownload.data.movies;
          gg.forEach((s) => {
            s.torrents.forEach((e) => {
              if (e.quality === "1080p") {
                this.setState({ getData: e.hash });
              }
              console.log(this.state.getData);
            });
          });

        }).catch(err => console.log(err));
    }
  }

  cancelModal = () => {
    this.props.hideFunc();
    this.setState({ display: true });
  };

  openLinks = () => {
    this.setState({ links: true });
  };

  closeLinks = () => {
    this.setState({ links: false });
  };

  submitLink = event => {
    event.preventDefault();
    if (this.state.movie_link) {
      updateLink(this.props.id, this.state.movie_link);
      this.setState({ addedmsg: true });
      setTimeout(() => window.location.reload(false), 3000);
    } else {
      this.setState({ errormsg: true });
    }
  };

  handleChange = event => {
    event.preventDefault();
    if (event.target.type === "text") {
      this.setState({ movie_link: event.target.value });
    }
  };

  changeServer = (server) => {
    this.setState({ server });
    localStorage.setItem("server_movie", server);
  };

  render() {
    const { isOpen, imdb, url, showId, imdb_id,id, tmdb } = this.props;
    const { cancelModal, changeServer } = this;
    const isVideoOpen = isOpen ? "is-modal-active" : "";
    const { server } = this.state;
    let link = '';

    if (server == 1) {
      link = `//watch.streamflix.one/movie/${imdb}/watch?server=1`;
    } else if (server == 2) {
      link = `//v2.vidsrc.me/embed/${imdb}`;
    } else if (server == 3) {
      link = `//vidsrc.vip/embed/movie/${imdb}`;
    } else if (server == 4) {
      link = `//vidsrc.pro/embed/movie/${imdb}`;
    } else if (server == 5) {
      link = `//vidsrc.cc/v2/embed/movie/${imdb}`;
    } else if (server == 6) {
      link = `//embed.warezcdn.com/filme/${imdb}`;
    }


 // } else if (server == 2) {
    //link = `//warezmovies-silk.vercel.app/media/tmdb-movie-${imdb}//soaper.netlify.app/embed/movie/${imdb}/`;

    const player = (
      <Download
        handleChange={cancelModal}
        url={url}
        isOpen={isOpen}
        link={link}
      />
    );

    return (
      <div className={`modal modal--fullscreen ${isVideoOpen}`}>
        <div className="modal__dialog">
          <div className="modal__content">
            <div className="server-buttons">
              <button onClick={() => changeServer(1)} className="server-button">1-NoAds</button>
              <button onClick={() => changeServer(2)} className="server-button">Opção-2</button>
              <button onClick={() => changeServer(3)} className="server-button">Opção-3</button>
              <button onClick={() => changeServer(4)} className="server-button">Opção-4</button>
              <button onClick={ () => changeServer(5) } className="server-button">Opção-5</button>
              <button onClick={ () => changeServer(6) } className="server-button">Opção-6</button>
            </div>
            {player}
          </div>
        </div>
      </div>
    );
  }
}

export default MovieServers;
