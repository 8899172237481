import React, { Component } from "react";
import "./Settings.scss";
import axios from "axios";
import { SignedIn, SignedOut } from "../UserState/UserState";

class Settings extends Component {
  state = {
    openMovie: false,
    openSeries: false,
    openLanguage: false,
    languagedata: "",
    movie: "",
    series: "",
    languagename: "",
    language: [],
  };

  componentDidMount() {
    let movie_name = localStorage.getItem('server_movie');
    let series_name = localStorage.getItem('server_series');
    this.setState({ languagename: localStorage.getItem('language_eng') });
    if (movie_name == 1) {
      this.setState({ movie: "WarezPlayer NonADS" });
    } else if (movie_name == 2) {
      this.setState({ movie: "iBomma" });
    } else if (movie_name == 4) {
      this.setState({ movie: "VidSrc PRO" });
    } else if (movie_name == 5) {
      this.setState({ movie: "Non-Embed" });
    }
    if (series_name == 1) {
      this.setState({ series: "WarezPlayer NonADS" });
    }

    axios.get(`https://api.themoviedb.org/3/configuration/languages?api_key=453752deba3272cd109112cd41127fd8`)
      .then((response) => {
        this.setState({
          language: response.data,
        })
      }).catch(err => console.log(err));
  }

  openServers = () => {
    this.setState({ openMovie: true });
  };

  closeLanguage = () => {
    this.setState({ openLanguages: false });
    this.setState({ languagename: localStorage.getItem('language') });
  }

  openSeries = () => {
    this.setState({ openSeries: true });
  };

  closeSeries = () => {
    this.setState({ openSeries: false });
  }

  closeServers = () => {
    this.setState({ openMovie: false });
  }

  openLanguages = () => {
    this.setState({ openLanguages: true });
  }

  chooseServer1 = () => {
    this.setState({ movie: "Servidor - 1" });
    localStorage.setItem('server_movie', 1);
    this.setState({ openMovie: false});
  }
  chooseServer2 = () => {
    this.setState({ movie: "Servidor - 2" });
    localStorage.setItem('server_movie', 2);
    this.setState({ openMovie: false});
  }
  
  chooseServer3 = () => {
    this.setState({ movie: "Servidor - 3" });
    localStorage.setItem('server_movie', 3);
    this.setState({ openMovie: false});
  }

  chooseServer4 = () => {
    this.setState({ movie: "Servidor - 4" });
    localStorage.setItem('server_movie', 4);
    this.setState({ openMovie: false});
  }

  chooseServer5 = () => {
    this.setState({ movie: "Servidor - 5" });
    localStorage.setItem('server_movie', 5);
    this.setState({ openMovie: false});
  }

  chooseServerSeries1 = () => {
    this.setState({ series: "Servidor - 1" });
    localStorage.setItem('server_series', 1);
    this.setState({ openSeries: false });
  }
  chooseServerSeries2 = () => {
    this.setState({ series: "Servidor - 2" });
    localStorage.setItem('server_series', 2);
    this.setState({ openSeries: false });
  }
  chooseServerSeries3 = () => {
    this.setState({ series: "Servidor - 3" });
    localStorage.setItem('server_series', 3);
    this.setState({ openSeries: false });
  }
  chooseServerSeries4 = () => {
    this.setState({ series: "Servidor - 4" });
    localStorage.setItem('server_series', 4);
    this.setState({ openSeries: false });
  }
  chooseServerSeries5 = () => {
    this.setState({ series: "Servidor - 5" });
    localStorage.setItem('server_series', 5);
    this.setState({ openSeries: false });
  }

  handleClick = (e, data) => {
    localStorage.setItem('language', data);
    this.setState({ openLanguages: false });
  }

  handleClick1 = (e, data) => {
    localStorage.setItem('language_eng', data);
    this.setState({ openLanguages: false });
    this.setState({ languagename: localStorage.getItem('language_eng') });
    window.location.reload(false);
  }

  render() {
    return (
      <div className="container settings">
        <div className="sc-1o36vqg-7 brWjXb">
          <header className="sc-1o36vqg-4 kYxrQw">
            <div className="sc-1o36vqg-3 iVeijx">
              <div className="sc-1o36vqg-0 hfwCMq">Altere o Idioma dos conteudos.</div>
            </div>
          </header>
          <hr className="sc-120wsjt-0 sc-1o36vqg-2 keqMWH" />
          <div className="sc-1o36vqg-6 hwytcN">
            <div className="zpicwb-12 erjEHL">
              <div className="zpicwb-10 gmjUwa">
                <div className="zpicwb-11 jBNrZF" onClick={this.openLanguages}>
                  <div className="zpicwb-3 bjxAPK">{ this.state.languagename ? this.state.languagename : "Select Language"}</div>
                </div>
                <div className="zpicwb-9 cHJOkB">
                  <svg onClick={this.closeLanguage} width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="m9d8o3-0 fxbeUc zpicwb-8 eKqRtN" main="#9B9D9F">
                    <path d="M17 9.5l-5 5-5-5" stroke="#9B9D9F" data-stroke="main" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                  </svg>
                  <ul className="sc-12gjfzm-1 hqidmZ zpicwb-0 jBOakZ" style={{display: this.state.openLanguages ? "block" : "none"}}>
                    {this.state.language && this.state.language.map((movie, i) => (
                      <li className="sc-12gjfzm-0 PMXqu" onClick={(e) => { this.handleClick(e, movie.iso_639_1); this.handleClick1(e, movie.english_name);}}>{movie.english_name}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="sc-1o36vqg-7 brWjXb">
          <header className="sc-1o36vqg-4 kYxrQw">
            <div className="sc-1o36vqg-3 iVeijx">
              <div className="sc-1o36vqg-0 hfwCMq">Altere para destravar o player de filmes</div>
            </div>
          </header>
          <hr className="sc-120wsjt-0 sc-1o36vqg-2 keqMWH" />
          <div className="sc-1o36vqg-6 hwytcN">
            <div className="zpicwb-12 erjEHL">
              <div className="zpicwb-10 gmjUwa">
                <div className="zpicwb-11 jBNrZF" onClick={this.openServers}>
                  <div className="zpicwb-3 bjxAPK">{ this.state.movie ? this.state.movie : "Select Server"}</div>
                </div>
                <div className="zpicwb-9 cHJOkB">
                  <svg onClick={this.closeServers} width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="m9d8o3-0 fxbeUc zpicwb-8 eKqRtN" main="#9B9D9F">
                    <path d="M17 9.5l-5 5-5-5" stroke="#9B9D9F" data-stroke="main" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                  </svg>
                  <ul className="sc-12gjfzm-1 hqidmZ zpicwb-0 jBOakZ" style={{display: this.state.openMovie ? "block" : "none"}}>
                    <li className="sc-12gjfzm-0 PMXqu" onClick={this.chooseServer1}>Opção 1</li>
                    <li className="sc-12gjfzm-0 PMXqu" onClick={this.chooseServer2}>Opção 2</li>
                    <li className="sc-12gjfzm-0 PMXqu" onClick={ this.chooseServer3 }>Opção 3</li>
                    <li className="sc-12gjfzm-0 PMXqu" onClick={ this.chooseServer4 }>Opção 4</li>
                    <li className="sc-12gjfzm-0 PMXqu" onClick={this.chooseServer5}>Opção 5</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="sc-1o36vqg-7 brWjXb">
          <header className="sc-1o36vqg-4 kYxrQw">
            <div className="sc-1o36vqg-3 iVeijx">
              <div className="sc-1o36vqg-0 hfwCMq">Altere para destravar o player da TV</div>
            </div>
          </header>
          <hr className="sc-120wsjt-0 sc-1o36vqg-2 keqMWH" />
          <div className="sc-1o36vqg-6 hwytcN">
            <div className="zpicwb-12 erjEHL">
              <div className="zpicwb-10 gmjUwa">
                <div className="zpicwb-11 jBNrZF" onClick={this.openSeries}>
                  <div className="zpicwb-3 bjxAPK">{ this.state.series ? this.state.series : "Select Server"}</div>
                </div>
                <div className="zpicwb-9 cHJOkB">
                  <svg onClick={this.closeSeries} width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="m9d8o3-0 fxbeUc zpicwb-8 eKqRtN" main="#9B9D9F">
                    <path d="M17 9.5l-5 5-5-5" stroke="#9B9D9F" data-stroke="main" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                  </svg>
                  <ul className="sc-12gjfzm-1 hqidmZ zpicwb-0 jBOakZ" style={{display: this.state.openSeries ? "block" : "none"}}>
                    <li className="sc-12gjfzm-0 PMXqu" onClick={ this.chooseServerSeries1 }>Opção 1</li>
                    <li className="sc-12gjfzm-0 PMXqu" onClick={ this.chooseServerSeries2 }>Opção 2</li>
                    <li className="sc-12gjfzm-0 PMXqu" onClick={ this.chooseServerSeries3 }>Opção 3</li>
                    <li className="sc-12gjfzm-0 PMXqu" onClick={ this.chooseServerSeries4 }>Opção 4</li>
                    <li className="sc-12gjfzm-0 PMXqu" onClick={this.chooseServerSeries5}>Opção 5</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Settings;
