import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

// Initialize Firebase
const config = {
    apiKey: "AIzaSyABnVpIo0sH-g5y1YNlk_nOgdUMGJdLha4",
    authDomain: "wztu-89ba0.firebaseapp.com",
    projectId: "wztu-89ba0",
    storageBucket: "wztu-89ba0.appspot.com",
    messagingSenderId: "920111247478",
    appId: "1:920111247478:web:399cd651ac9c93e4512f57",
    measurementId: "G-NMYXEJTDCN"
};

const firebaseApp = firebase.initializeApp(config);

// Initialize the Firestore database
const db = firebase.firestore();
const auth = firebase.auth();

export { db, auth };
export default firebaseApp;