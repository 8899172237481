import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import DetailsBanner from "../DetailsBanner/DetailsBanner";
import DetailsTitle from "../DetailsTitle/DetailsTitle";
import MovieInformation from "../MovieInformation/MovieInformation";
import LoadingDetailsPage from "../Loading/LoadingDetailsPage";
import Trailers from "../Trailers/Trailers";

/**
 * Markup for the details page
 */
function DetailsPage({ currentMovie, isLoading }) {
  const [trailers, setTrailers] = useState([]);

  useEffect(() => {
    if (currentMovie.videos && currentMovie.videos.results) {
      setTrailers(currentMovie.videos.results);
    }
  }, [currentMovie.videos]);

  if (isLoading) return <LoadingDetailsPage />;

  return (
    <div id="detailspage" style={{ overflowX: "hidden" }}>
      <DetailsBanner backdropPath={currentMovie.backdrop_path} trailers={trailers} />
      <DetailsTitle movie={currentMovie} />
      <MovieInformation currentMovie={currentMovie} />
      <h2 style={{ textAlign: "center" }}>Trailers</h2>
      <div style={{ overflowX: "hidden" }}>
        <Trailers trailers={trailers} />
      </div>
    </div>
  );
}

DetailsPage.propTypes = {
  currentMovie: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default DetailsPage;
