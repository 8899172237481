// src/components/VideoPlayer.jsx
import React, { useRef, useEffect } from "react";
import Hls from "hls.js";

const VideoPlayer = ({ src }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;
    const hls = new Hls();

    hls.loadSource(src);
    hls.attachMedia(video);

    hls.on(Hls.Events.MANIFEST_PARSED, () => {
      video.play();
    });

    hls.on(Hls.Events.ERROR, (event, data) => {
      console.error('HLS.js error:', data);
      if (data.details === 'manifestParsingError' || data.details === 'manifestLoadError') {
        alert('Erro ao carregar o manifesto HLS. Verifique o proxy e a URL do vídeo.');
      }
    });

    return () => {
      hls.destroy();
    };
  }, [src]);

  return (
    <div>
      <video ref={videoRef} controls style={{ width: '100%' }} />
    </div>
  );
};

export default VideoPlayer;
