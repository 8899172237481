// src/components/IPTVPlayer.jsx
import React from "react";
import VideoPlayer from "./VideoPlayer";

const IPTVPlayer = ({ src }) => {
  const encodeUrl = (url) => {
    return encodeURIComponent(url);
  };

  const proxiedUrl = `https://m3u8-proxy-cors-rho-umber.vercel.app/cors?url=${encodeUrl(src)}`;

  return (
    <div>
      <h2>IPTV Player</h2>
      <VideoPlayer src={proxiedUrl} />
    </div>
  );
};

export default IPTVPlayer;
