import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Seo from "../Seo";
import "./Questions.scss";

class Questions extends Component {
  render() {
    return (
      <div className="container help-center">
        <Seo 
          title="Centro de Ajuda"
          description="As Perguntas Frequentes (FAQs) desta página são para o público em geral e contêm respostas às perguntas mais comuns."
        />
        <h1>Centro de Ajuda</h1>
        <div className="wrapper">
          <div className="lista-item">
            <input className="trigger-input" id="faq-titulo-11" type="checkbox" />
            <div className="trigger-wrapper">
              <label htmlFor="faq-titulo-11">
                <h2 className="faq-titulo">O que é o Projeto Wareztuga?</h2>
              </label>
              <p className="faq-conteudo">
                <span style={{ color: '#4784ff' }}><b>Wareztuga</b></span> é um site de streaming que oferece filmes e programas de TV gratuitamente.
                Se quiser um serviço melhor de forma <b>legal</b>, é melhor subscrever ao <b>HBO, Netflix, Hulu, Amazon, Apple, Disney.</b>
                Vá aos cinemas ou alugue filmes!
              </p>
            </div>
          </div>

          <div className="lista-item">
            <input className="trigger-input" id="faq-titulo-1" type="checkbox" />
            <div className="trigger-wrapper">
              <label htmlFor="faq-titulo-1">
                <h2 className="faq-titulo">É seguro transmitir e fazer download neste site?</h2>
              </label>
              <p className="faq-conteudo">
                Usar este site é seguro para transmissão. 
                Mas fazer download, upload e distribuição é ilegal. 
                Os utilizadores não terão problemas ao usar o nosso site. 
                Não é recomendável fazer download dos ficheiros e partilhá-los publicamente, 
                pode meter-se em sarilhos.
                O Warez não é responsavél por qualquer download ou conteudo salvo em seus dispositivos.

              </p>
            </div>
          </div>

          <div className="lista-item">
            <input className="trigger-input" id="faq-titulo-2" type="checkbox" />
            <div className="trigger-wrapper">
              <label htmlFor="faq-titulo-2"><h2 className="faq-titulo">FREE ADS?</h2></label>
              <p className="faq-conteudo">
                Os conteúdos são fornecidos por terceiros não afiliados. [Vidcloud, Hydrax] O criador do site <b>não está a lucrar</b>. 
                Todos os anúncios (Ex. Pop-ups, Banners) são dos sites de hospedagem.
              </p>
            </div>
          </div>

          <div className="lista-item">
            <input className="trigger-input" id="faq-titulo-3" type="checkbox" />
            <div className="trigger-wrapper">
              <label htmlFor="faq-titulo-3">
                <h2 className="faq-titulo">Há muitos anúncios.</h2>
              </label>
              <p className="faq-conteudo">
                Pedimos desculpa, mas não podemos ajudar com isso. 
                Não temos controlo sobre os anúncios apresentados. 
                (Podem ser Malware, Anúncios Pornográficos) Não faça download de nada nos pop-ups. Se não quiser ser incomodado, 
                recomendo fortemente subscrever a um serviço de streaming legal que possa pagar. <br /><br /><div style={{ color: '#4784ff' }}>(Sim, vale a pena!)</div>
              </p>
            </div>
          </div>

          <div className="lista-item">
            <input className="trigger-input" id="faq-titulo-4" type="checkbox" />
            <div className="trigger-wrapper">
              <label htmlFor="faq-titulo-4">
                <h2 className="faq-titulo">Por que os filmes ou séries demoram a carregar?</h2>
              </label>
              <p className="faq-conteudo">
                O carregamento dos filmes ou séries depende da sua conexão à internet. Normalmente, leva de 3 a 10 segundos em alguns dispositivos com uma conexão média, por favor, seja paciente.
              </p>
            </div>
          </div>

          <div className="lista-item">
            <input className="trigger-input" id="faq-titulo-5" type="checkbox" />
            <div className="trigger-wrapper">
              <label htmlFor="faq-titulo-5">
                <h2 className="faq-titulo">Não consigo aceder ao site, ajudem-me!</h2>
              </label>
              <p className="faq-conteudo">
                Tente mudar de navegador (Chrome, Firefox, etc.).
              </p>
            </div>
          </div>

          <div className="lista-item">
            <input className="trigger-input" id="faq-titulo-6" type="checkbox" />
            <div className="trigger-wrapper">
              <label htmlFor="faq-titulo-6">
                <h2 className="faq-titulo">Experiencio bugs ou erros. O que devo fazer agora?</h2>
              </label>
              <p className="faq-conteudo">
                Se experienciar bugs, tente limpar o cache primeiro e depois tentar abrir novamente. Se o problema persistir, reporte-nos para que possamos corrigir o mais rápido possível.
              </p>
            </div>
          </div>

          <div className="lista-item">
            <input className="trigger-input" id="faq-titulo-8" type="checkbox" />
            <div className="trigger-wrapper">
              <label htmlFor="faq-titulo-8">
                <h2 className="faq-titulo">Aviso Legal</h2>
              </label>
              <p className="faq-conteudo">
                Este site não armazena nenhum ficheiro no seu servidor. Todos os conteúdos são fornecidos por terceiros não afiliados e todos os ficheiros já circulam online!
              </p>
            </div>
          </div>

          <div className="lista-item">
            <input className="trigger-input" id="faq-titulo-7" type="checkbox" />
            <div className="trigger-wrapper">
              <label htmlFor="faq-titulo-7">
                <h2 className="faq-titulo">Conecte-se Connosco!</h2>
              </label>
              <p className="faq-conteudo">
                Siga-nos em:<br />
                <div style={{ color: '#4784ff' }}>
                  <a href="https://discord.gg/ay8Q5Uh82J" target="_blank" rel="noopener noreferrer">Discord.</a>
                </div>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Questions);
