import React, { useState, useEffect } from "react";
import { AiOutlineDownload } from "react-icons/ai";
import styles from "./DownloadButton.module.scss";

const DownloadButton = () => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isIOS, setIsIOS] = useState(false);
  const [isInstalled, setIsInstalled] = useState(false);

  useEffect(() => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    const isIosDevice = /iphone|ipad|ipod/.test(userAgent);

    setIsIOS(isIosDevice);

    if (window.navigator.standalone === true || window.matchMedia('(display-mode: standalone)').matches) {
      setIsInstalled(true);
    }

    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
    };
  }, []);

  const handleDownload = async () => {
    if (isInstalled) {
      alert("O app já está instalado, verifique em seus aplicativos.");
      return;
    }

    if (isIOS) {
      alert(
        "Para adicionar o app à sua tela inicial no iOS, toque no ícone de compartilhamento e selecione 'Adicionar à Tela de Início'."
      );
    } else if (deferredPrompt) {
      deferredPrompt.prompt();
      const { outcome } = await deferredPrompt.userChoice;
      if (outcome === "accepted") {
        setDeferredPrompt(null);
      }
    }
  };

  return (
    <button onClick={handleDownload} className={styles.downloadButton}>
      <AiOutlineDownload />
    </button>
  );
};

export default DownloadButton;
