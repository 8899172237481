// src/components/MainComponent.jsx
import React, { useState } from "react";
import ChannelList from "./ChannelList";
import IPTVPlayer from "./IPTVPlayer";
import './styles.css';

const MainComponent = () => {
  const [selectedChannel, setSelectedChannel] = useState(null);

  const handleSelectChannel = (url) => {
    console.log('Selected channel URL:', url);
    setSelectedChannel(url);
  };

  return (
    <div className="main-container">
      <h1>Player de Vídeo HLS com Slider de Canais</h1>
      <div className="content">
        <ChannelList onSelectChannel={handleSelectChannel} />
        {selectedChannel && <IPTVPlayer src={selectedChannel} />}
      </div>
    </div>
  );
};

export default MainComponent;
