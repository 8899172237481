  import React from "react";
  import PropTypes from "prop-types";
  import { Link } from "react-router-dom";
  import LoadingGrid from "../Loading/LoadingGrid";
  import PosterGrid from "../PosterGrid/PosterGrid";

  import { SignedIn, SignedOut } from "../UserState/UserState";
  import Seo from "../Seo";
  import "./Homepage.scss";
  import Announcement from "../Ads/Announcement";

  function Homepage({ 
    documentary, 
    movies,
    network, 
    netflix, 
    hbo,
    prime,
    disney,
    apple,
    star,
    paramount,
    globoplay,
    thriller, 
    kids, 
    series, 
    scifi, 
    horror, 
    loadingMovies, 
    loadingScifi, 
    loadingKids, 
    loadingThriller, 
    loadingHorror, 
    loadingShows, 
    loadingDocumentaries,
    loadingNetflix,
    loadingHbo,
    loadingPrime,
    loadingDisney,
    loadingApple,
    loadingStar,
    loadingParamount,
    loadingGloboplay,
  }) {
    
    return (
    <>
    <Seo 
    title="Wareztuga - Filmes e Séries Online Legendados em Português" 
    keywords="filmes grátis, séries online, assistir filmes, download de filmes, filmes HD, séries HD, Wareztuga, filmes legendados, séries legendadas, assistir filmes em português, assistir séries em português" 
    description="Wareztuga, a melhor Plataforma Portuguêsa para assistir e fazer download de Filmes e TV Séries online legendados em português de Portugal. Aproveite uma vasta coleção de entretenimento em qualidade HD, tudo gratuitamente sem Anúncios!" 
  />

      <div id="homepage" className="container">
      <Announcement />
        <div className="home"> </div>
        <section>
          <div className="main-blogs">
            <SignedIn>
            {() => (
            <>
            <div className="main-blog anim" style={{ "--delay": ".1s" }}>
              <div className="main-blog__title">
              Devido a políticas externas, iremos adicionar login obrigatório
              para conseguir visualizar o conteúdo! <br />
              </div>
              <div className="main-blog__author">
                <div className="author-detail">
                  <div className="author-name">Novas atualizações a caminho!</div>
                  <div className="author-info">
                    Sem Publicidade<span className="seperate"></span>WatchParty<span className="seperate"></span>4K Legendas pt-PT!</div>
                </div>
              </div>
              <div className="main-blog__time">
                <Link className="buttongg" to="/movies/year/2024">RECENTES!</Link>
              </div>
            </div>
            <div className="main-blog anim" style={{ "--delay": ".2s" }}>
              <div className="main-blog__title">
                Gostas do Wareztuga? Compartilhe com os seus amigos!.
              </div>
              <div className="main-blog__author">
                <div className="author-detail">
                  <div className="author-name">Entre em nosso grupo Oficial!</div>
                  <div className="author-info">
                    Novidades <span className="seperate"></span> Logs<span className="seperate"></span> NSFW
                  </div>
                </div>
              </div>
              <div className="main-blog__time">
                <Link className="buttongg" to="/discord">
                  ENTRAR NO GRUPO
                </Link>
              </div>
            </div>
            </>
            )}
            </SignedIn>
            <SignedOut>
            <div className="main-blog anim" style={{ "--delay": ".2s" }}>
              <div className="main-blog__title">
              Entidades reguladoras estão tentando desativar o Warez! <br />
              Junte-se ao canal Discord para nunca perder o acesso ao site!
              </div>
              <div className="main-blog__author">
                <div className="author-detail">
                  <div className="main-blog__author">
                    <div className="author-img__wrapper">
                      <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check"><path d="M20 6L9 17l-5-5"></path></svg>
                    <img className="author-img" src="https://i.imgur.com/pR1jZly.png" />
                  </div>
                  <div className="auth-detail">
                    <div className="author-name">Sem Publicidade!</div>
                    <div className="author-info">Chromecast<span className="seperate"></span>CC<span className="seperate"></span>Watchlist</div>
                  </div>
                </div>
                </div>
              </div>
              <div className="main-blog__time">
                <Link className="buttongg" to="/movies/year/2024">
                Lançamentos!
                </Link>
              </div>
            </div>
            <div className="main-blog anim" style={{ "--delay": ".1s" }}>
              <div className="main-blog__title">
              Gostas do Warez? Junta-te ao Discord e nunca percas o acesso ao site.
              </div>
              <div className="main-blog__author">
                <div className="author-detail">
                  <div className="author-name">Junte-se ao nosso grupo oficial de discord </div>
                  <div className="author-info">
                  Código de convite<span className="seperate"></span> Brindes<span className="seperate"></span> Recompensas
                  </div>
                </div>
              </div>
              <div className="main-blog__time1">
                <Link className="buttongg" to="#">
                  Telegram
                </Link>
              </div>
              <div className="main-blog__time">
                <Link className="buttongg" to="/discord">
                Discord
                </Link>
              </div>
            </div>
            </SignedOut>
          </div>
        </section>{/*<h2>Recentes</h2>
          {loadingMovies && <LoadingGrid grid="true" />}
          {!loadingMovies && <PosterGrid movies={movies} view="grid" type="movie" /> }
            </section> */}
        <section>
        <h2>TOP NETFLIX</h2>
          {loadingNetflix && <LoadingGrid grid="true" />}
          {!loadingNetflix && <PosterGrid movies={netflix} view="grid" type="tv" /> }
        </section>
        <section>
        <h2>TOP HBO</h2>
          {loadingHbo && <LoadingGrid grid="true" />} {/* Corrigido para começar com letra minúscula */}
          {!loadingHbo && <PosterGrid movies={hbo} view="grid" type="tv" />} {/* Corrigido para começar com letra minúscula */}
          </section>
          <section>
        <h2>TOP PRIME VIDEO</h2>
          {loadingPrime && <LoadingGrid grid="true" />}
          {!loadingPrime && <PosterGrid movies={prime} view="grid" type="tv" />}
          </section>
          <section>
      <h2>TOP DISNEY+</h2>
          {loadingDisney && <LoadingGrid grid="true" />}
          {!loadingDisney && <PosterGrid movies={disney} view="grid" type="tv" />}
          </section>
          <section>
        <h2>TOP APPLE TV</h2>
          {loadingApple && <LoadingGrid grid="true" />}
          {!loadingApple && <PosterGrid movies={apple} view="grid" type="tv" />}
          </section>
          <section>
        <h2>TOP STAR+</h2>
          {loadingStar && <LoadingGrid grid="true" />}
          {!loadingStar && <PosterGrid movies={star} view="grid" type="tv" />}
          </section>
          <section>
        <h2>TOP PARAMOUNT+</h2>
          {loadingParamount && <LoadingGrid grid="true" />}
          {!loadingParamount && <PosterGrid movies={paramount} view="grid" type="tv" />}
          </section>
          <section>
        <h2>TOP GLOBOPLAY</h2>
          {loadingGloboplay && <LoadingGrid grid="true" />}
          {!loadingGloboplay && <PosterGrid movies={globoplay} view="grid" type="tv" />}
          </section>    
        <section>
        <h2>Top Thriller</h2>
          {loadingThriller && <LoadingGrid grid="true" />}
          {!loadingThriller && <PosterGrid movies={thriller} view="grid" type="movie" /> }
          </section>
          <section>
        <h2>Top Sci-Fi</h2>
          {loadingScifi && <LoadingGrid grid="true" />}
          {!loadingScifi && <PosterGrid movies={scifi} view="grid" type="movie" /> }
          </section>
          <section>
        <h2>Top Kids</h2>
          {loadingKids && <LoadingGrid grid="true" />}
          {!loadingKids && <PosterGrid movies={kids} view="grid" type="movie" /> }
          </section>
        <section>
        <h2>Top Terror</h2>
          {loadingHorror && <LoadingGrid grid="true" />}
          {!loadingHorror && <PosterGrid movies={horror} view="grid" type="movie" /> }
        </section>
        <section>
          <div className="main-blogs">
            <div className="main-blog1">
              <div className="main-blog__title">Mantenha-se atualizado sobre o que está acontecendo!</div>
              <div className="main-blog__author tips">
                <div className="main-blog__time">
                
                  <a className="buttongg" href="https://discord.gg/ay8Q5Uh82J" target="_blank" rel="noreferrer">Discord</a>
                
                </div>
                <div className="author-detail">
                  <div className="author-name">Todas as informações estão aqui</div>
                  <div className="author-info">
                    Comunicado<span className="seperate"></span>Fix & Issues<span className="seperate"></span>e mais
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h2>Series no ar</h2>
          {loadingShows && <LoadingGrid grid="true" />}
          {!loadingShows && <PosterGrid movies={series} view="grid" type="tv" />}
        </section>
        <section>
        <h2>Top Documentarios</h2>
          {loadingDocumentaries && <LoadingGrid grid="true" />}
          {!loadingDocumentaries && <PosterGrid movies={documentary} view="grid" type="tv" /> }
        </section>
        </div>
    </>
    );
  }

  Homepage.propTypes = {
    movies: PropTypes.array.isRequired,
    series: PropTypes.array.isRequired,
    horror: PropTypes.array.isRequired,
    thriller: PropTypes.array.isRequired,
    kids: PropTypes.array.isRequired,
    scifi: PropTypes.array.isRequired,
    documentary: PropTypes.array.isRequired,
    netflix: PropTypes.array.isRequired,
    hbo: PropTypes.array.isRequired,
    prime: PropTypes.array.isRequired,
    disney: PropTypes.array.isRequired,
    apple: PropTypes.array.isRequired,
    star: PropTypes.array.isRequired,
    paramount: PropTypes.array.isRequired,
    globoplay: PropTypes.array.isRequired,
    loadingMovies: PropTypes.bool.isRequired,
    loadingScifi: PropTypes.bool.isRequired,
    loadingKids: PropTypes.bool.isRequired,
    loadingThriller: PropTypes.bool.isRequired,
    loadingHorror: PropTypes.bool.isRequired,
    loadingShows: PropTypes.bool.isRequired,
    loadingDocumentaries: PropTypes.bool.isRequired,
    loadingNetflix: PropTypes.bool.isRequired,
    loadingHbo: PropTypes.bool.isRequired,
    loadingPrime: PropTypes.bool.isRequired,
    loadingDisney: PropTypes.bool.isRequired,
    loadingApple: PropTypes.bool.isRequired,
    loadingStar: PropTypes.bool.isRequired,
    loadingParamount: PropTypes.bool.isRequired,
    loadingGloboplay: PropTypes.bool.isRequired,
  };


  export default Homepage;
