import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { FaVolumeMute, FaVolumeUp } from "react-icons/fa";
import ImageWithFallback from "../ImageWithFallback/ImageWithFallback";
import "./DetailsBanner.scss";

function DetailsBanner({ backdropPath, trailers }) {
  const [isMuted, setIsMuted] = useState(true);
  const [trailerKey, setTrailerKey] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    if (trailers.length > 0) {
      setTrailerKey(trailers[0].key);
    }
  }, [trailers]);

  const handleResize = useCallback(() => {
    setWindowWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  const toggleMute = () => {
    setIsMuted(!isMuted);
  };

  return (
    <div id="banner">
      {trailerKey ? (
        <div className="video-container">
          <iframe
            key={windowWidth}  // Força a re-renderização ao redimensionar
            className="trailer-video"
            src={`https://www.youtube.com/embed/${trailerKey}?autoplay=1&mute=${isMuted ? 1 : 0}&controls=0&loop=1&playlist=${trailerKey}&modestbranding=1&rel=0&iv_load_policy=3&fs=0&playsinline=1`}
            frameBorder="0"
            allow="autoplay; encrypted-media"
            allowFullScreen
            title="trailer"
          />
          <div className="gradient-overlay"></div>
          <button className="mute-button" onClick={toggleMute}>
            {isMuted ? (
              <FaVolumeMute size={48} color="white" />
            ) : (
              <FaVolumeUp size={48} color="white" />
            )}
          </button>
        </div>
      ) : (
        <ImageWithFallback className="banner-img" src={backdropPath} alt="Backdrop" />
      )}
      <div className="gradient" />
      <div className="curve-edge"></div>
    </div>
  );
}

DetailsBanner.defaultProps = {
  backdropPath: "",
  trailers: [],
};

DetailsBanner.propTypes = {
  backdropPath: PropTypes.string,
  trailers: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
    })
  ),
};

export default DetailsBanner;
