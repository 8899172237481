import React, { Component } from "react";
import Download from "../Torrents/DownloadItem.jsx";
import { Link } from "react-router-dom";
import './styles.css'; // Certifique-se de ajustar o caminho conforme necessário

class EpisodeServers extends Component {
  state = {
    selectedOption: "",
    openVideo: false,
    openNotif: false,
    iframeLoaded: false,
    display: true,
    populardownload: [],
    pop: [],
    links: false,
    fileStatus: [],
    loading: true,
    server: localStorage.getItem("server_series") || 1, // adiciona o estado do servidor
  };

  handleChange = (selectedOption) => {
    this.setState({ selectedOption: selectedOption.value });
    this.setState({ serverStatus: false });
    this.setState({ openNotif: true });
  };

  openServers = () => {
    this.setState({ serverStatus: true });
  };

  closeServers = () => {
    this.setState({ serverStatus: false });
  };

  closeNotif = () => {
    this.setState({ openNotif: false });
  };

  cancelModal = () => {
    this.props.hideFunc();
    this.setState({ iframeLoaded: false });
  };

  closeDisplay = () => {
    this.setState({ display: false });
  };

  openIframe = () => {
    this.setState({ iframeLoaded: true });
    this.setState({ openNotif: false });
  };

  openLinks = () => {
    this.setState({ links: true });
  };

  closeLinks = () => {
    this.setState({ links: false });
  };

  changeServer = (server) => {
    this.setState({ server });
    localStorage.setItem("server_series", server);
  };

  render() {
    const { isOpen, showId, imdb_id, url, episodeNumber, seasonNumber, contentName } = this.props;
    const { cancelModal, changeServer } = this;
    const isVideoOpen = isOpen ? "is-modal-active" : "";
    const { server } = this.state;
    let link = '';

    if (server == 1) {
      link = `//watch.streamflix.one/tv/${showId}/watch?server=1&${seasonNumber}&${episodeNumber}`;
    } else if (server == 2) {
      link = `//vidsrc.xyz/embed/tv/${showId}/${seasonNumber}/${episodeNumber}`;
    } else if (server == 3) {
      link = `//vidsrc.vip/embed/tv/${showId}/${seasonNumber}/${episodeNumber}`;
    } else if (server == 4) {
      link = `//vidsrc.pro/embed/tv/${showId}/${seasonNumber}/${episodeNumber}`;
    } else if (server == 5) {
      link = `//vidsrc.cc/v2/embed/tv/${showId}/${seasonNumber}/${episodeNumber}`;
    } else if (server == 6) {
      link = `//embed.warezcdn.com/serie/${showId}/${seasonNumber}/${episodeNumber}`;
    }

 // } else if (server == 2) {
    //link = `//warezmovies-silk.vercel.app/media/tmdb-tv-${showId}-${contentName}/${seasonNumber}/${episodeNumber}`; //soaper.netlify.app/embed/tv/${showId}/${seasonNumber}/${episodeNumber}/`;

    const player = (
      <Download
        handleChange={cancelModal}
        url={url}
        isOpen={isOpen}
        link={link}
      />
    );

    return (
      <div className={`modal modal--fullscreen ${isVideoOpen}`}>
        <div className="modal__dialog">
          <div className="modal__content">
            <div className="server-buttons">
              <button onClick={() => changeServer(1)} className="server-button">1-NoAds</button>
              <button onClick={() => changeServer(2)} className="server-button">Opção-2 </button>
              <button onClick={() => changeServer(3)} className="server-button">Opção-3 </button>
              <button onClick={() => changeServer(4)} className="server-button">Opção-4 </button>
              <button onClick={ () => changeServer(5) } className="server-button">Opção-5 </button>
              <button onClick={ () => changeServer(6) } className="server-button">Opção-6 </button>
            </div>
            {player}
          </div>
        </div>
      </div>
    );
  }
}

export default EpisodeServers;
