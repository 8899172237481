import React from "react";
import PropTypes from "prop-types";
import { withRouter, Route, Switch } from "react-router-dom";
import Searchbar from "../Searchbar/Searchbar";
import DarkModeToggle from "../Darkmode/DarkModeToggle";
import DownloadButton from "../Download/DownloadButton";
import DonateButton from "./DonateButton";
import { SignedIn, SignedOut } from "../UserState/UserState";
import { signOut } from "../../Firebase/UserUtils";
import UserIcon from "../../assets/user.svg"; // Importando o ícone de usuário
import "./Header.scss";

class DesktopHeader extends React.Component {
  static propTypes = {
    searchbarValue: PropTypes.string.isRequired,
    searchHandler: PropTypes.func.isRequired,
    setSearchbarValue: PropTypes.func.isRequired,
  };

  handleLogout = () => {
    signOut().then(() => {
      this.props.history.push('/login');
    });
  };

  render() {
    const { searchbarValue, searchHandler, setSearchbarValue } = this.props;

    return (
      <header id="app-header-desktop" className="app-header">
        <Searchbar
          value={searchbarValue}
          search={searchHandler}
          setSearchbarValue={setSearchbarValue}
        />
        <div className="darkmode-toggle">
          <DonateButton />
          <DownloadButton />
          <DarkModeToggle />
        </div>
        <div className="user-actions">
          <SignedIn>
            {user => (
              <button onClick={this.handleLogout} className="user-button">
                <UserIcon fill="currentColor" />
                <span className="nav-text">Logout</span>
              </button>
            )}
          </SignedIn>
          <SignedOut>
            <button onClick={() => this.props.history.push('/login')} className="user-button">
              <UserIcon fill="currentColor" />
              <span className="nav-text">Login</span>
            </button>
          </SignedOut>
        </div>
      </header>
    );
  }
}

export default withRouter(DesktopHeader);
