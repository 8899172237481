import React, { useEffect } from 'react';

const DonateButton = () => {
  useEffect(() => {
    // Verifica se o script já foi carregado
    if (!document.querySelector('script[src="https://www.paypalobjects.com/donate/sdk/donate-sdk.js"]')) {
      const script = document.createElement('script');
      script.src = 'https://www.paypalobjects.com/donate/sdk/donate-sdk.js';
      script.setAttribute('charset', 'UTF-8');
      script.onload = () => {
        if (window.PayPal) {
          window.PayPal.Donation.Button({
            env: 'production',
            hosted_button_id: 'WAW83RHUA4VX8',
            image: {
              src: 'https://www.paypalobjects.com/pt_PT/PT/i/btn/btn_donate_SM.gif',
              alt: 'Faça donativos com o botão PayPal',
              title: 'PayPal - The safer, easier way to pay online!',
              width: '150px',
              height: '50px'
            }
          }).render('#donate-button');
        }
      };
      document.body.appendChild(script);
    } else {
      // Se o script já foi carregado, apenas renderiza o botão
      if (window.PayPal) {
        window.PayPal.Donation.Button({
          env: 'production',
          hosted_button_id: 'WAW83RHUA4VX8',
          image: {
            src: 'https://www.paypalobjects.com/pt_PT/PT/i/btn/btn_donate_SM.gif',
            alt: 'Faça donativos com o botão PayPal',
            title: 'PayPal - The safer, easier way to pay online!',
            width: '150px',
            height: '50px'
          }
        }).render('#donate-button');
      }
    }
  }, []);

  return (
    <div id="donate-button-container">
      <div id="donate-button"></div>
    </div>
  );
};

export default DonateButton;
